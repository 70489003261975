const WellnessBooster = (
  <div>
    {/* <h2>Empower Her: Women's Trauma Healing Group</h2> */}
    <p>
      Join us for EmpowerHer: Women's Trauma Healing Group, an 8-week journey
      designed to foster healing and understanding in a supportive environment.
      Each 90-minute session explores a different aspect of trauma recovery,
      from understanding the impacts of trauma to engaging in transformative
      healing practices. Together, we'll delve into trauma-conscious movement,
      breathwork, and sound meditation, alongside traditional support circles,
      creating a holistic approach to healing both body and mind. Discover
      empowerment and healing through restoring and renewing with us!!
      <br />
      <br />
      <strong style={{ color: "#839abf" }}>
        Duration: 90 minutes; 8-weeks; Closed Group; Fees: Private Pay $50 per
        session
      </strong>
    </p>
  </div>
);

export default WellnessBooster;

import "./professionalBio.css";

const NicoleBioContent = (
  <div className="info-box-style">
    <p>
      Along my journey in becoming a therapist, one mantra has stuck with me
      throughout: “You are the expert of your life.” This mantra encompasses my
      main beliefs as a therapist— that clients are the experts of their lived
      experiences. As your therapist, I hope to help you find agency and
      alignment with the truest version of yourself. Through mindfulness and
      intentionality, I hope to create a safe space to challenge, grow and
      maintain the values that can help you nurture your highest self.
      <br />
      <br />
      While approaching therapy from a decolonizing and neurodivergent-affirming
      lens, I support clients towards radical healing from trauma, anxiety, and
      stress. I understand the struggles of navigating life in a world that was
      not designed to hold space for you. I believe in honoring and respecting
      your lived experience while bringing awareness to the societal and
      systemic forces that affect your daily choices.
    </p>
  </div>
);

export default NicoleBioContent;

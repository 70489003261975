const IndividualTherapy = (
  <div>
    <p>
      Let’s talk one-on-one in a safe, non-judgmental, relaxed, and supportive
      environment. Allow me to gain an understanding of your past and present
      experiences through your lens. Let’s explore the impact of your emotions
      and thoughts on present behaviors. Let’s align to RELEASE, RESTORE and
      REDEFINE the next phase of your journey!
      <br />
      <br />
      <strong style={{ color: "#839abf" }}>
        Duration: 1 Hour; Private Pay Fee: $175/$125 (Sliding Scale Available)
      </strong>
    </p>
  </div>
);

export default IndividualTherapy;

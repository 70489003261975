import "../../../pages/offerings/offerings.css";
const AboutEDMR = (
  <div>
    <p className="transition-text">
      EMDR Intensives offer an alternative path to healing by rewiring memory
      networks rather than simply altering thought patterns to change emotions.
      Through EMDR processing, memories that contribute to feeling stuck in the
      past are desensitized, allowing for transformation of negative feelings,
      beliefs, and body sensations. These Intensives provide a condensed
      opportunity to address past, present, and future concerns, expediting the
      healing process.
      <br />
      <br />
      Typically spanning 4 to 8 hours per day over 1 to 5 days, Intensives offer
      concentrated time to address specific issues. While the work is intense,
      it can serve as a profound emotional reset. To ensure suitability for
      Intensive work, a complimentary consultation is provided. Clients are
      welcome to travel to Augusta for the Intensive, creating space for focused
      healing before returning to their regular routines. Don't hesitate to
      reach out and schedule your free consultation today—why wait to begin your
      healing journey?
    </p>
  </div>
);

export default AboutEDMR;

import "./professionalJourney.css";

const TiffanyProfessionalJourney = (
  <div className="professional-journey">
    <p>
      Licensed Professional Counselor:
      <br />
      <strong>
        Georgia Composite Board of Professional Counselors, Social Workers, and
        Marriage &amp; Family Therapist
      </strong>
      <br />
      <br />
      Certified Professional Clinical Supervisor: <br />
      <strong>LPCGA</strong>
      <br />
      <br />
      EMDRIA Certified EMDR Therapist
      <br />
      <br />
      Certified Holistic Healer
      <br />
      <br />
      CYT 300, Certified Yoga Therapist
      <br />
      <br />
      Certified, Trauma Conscious Yoga Method
      <br />
      <br />
      IFS Level One Trained Therapist
      <br />
      <br />
      Master of Science concentration Community Counseling: <br />
      <strong>Columbus State University</strong>
      <br />
      <br />
      Bachelor of Science concentration Psychology: <br />
      <strong>Wesleyan College</strong>
      <br />
      <br />
    </p>
  </div>
);

export default TiffanyProfessionalJourney;

import "../professionalBio/professionalBio.css";

const TiffanyBioContent = (
  <div className="info-box-style">
    <p>
      Hey, hey! I’m Tiffany.
      <br />
      <br />I am a Licensed Professional Counselor with over 15 years of
      clinical experience. I am a Certified Professional Clinical Supervisor,
      Certified Holistic Therapist, and EMDR Certified provider. I am Certified
      in the{" "}
      <a
        href="https://traumaconsciousyoga.com/"
        target="_blank"
        rel="noreferrer"
      >
        Trauma Conscious Yoga Method
      </a>{" "}
      and I’m also known as The Cookie Counselor. Yep, I bake to release stress,
      ground myself, and enrich my creative sweets passion.
      <br />
      <br />
      My hobby turned small business,{" "}
      {<a href="https://thecookierelease.com">The Cookie Release</a>}, allows me
      the opportunity to sow seeds and enhance my community’s optimal health.
      How? I dedicate a portion of all of my sales proceeds towards resources
      for my clients here – grounding tools and workshops.
      <br />
      <br />
      I am here to empower, support and align, while being trustworthy,
      relatable, consistent and professional. I have had the privilege of
      serving in multiple clinical capacities throughout my career. I am
      passionate and committed to helping individuals heal from sexual trauma,
      explore traumatic experiences, anxiety, self-esteem, depression and grief.
      <br />
      <br />
      While being culturally humble, I incorporate Integrative and Holistic
      modalities, in a trauma informed and person-centered environment. During
      your journey, you deserve to be supported as you release, restore, and
      redefine!
    </p>
  </div>
);

export default TiffanyBioContent;

const privateWellnessExperience = (
  <div>
    <h2>Private Wellness Experience</h2>
    <p>
      Whether you're celebrating a birthday, grand opening, anniversary,
      bachelorette party, or planning a self-care day, consider adding a unique
      touch of wellness to your event. Choose from Trauma-Conscious Yoga,
      Breathwork, Meditation, or a Sound Bath session—or enjoy a customized mix
      of these offerings. Each practice is designed to create a space for
      relaxation, connection, and holistic rejuvenation.
      <br />
      <br />
      <strong style={{ color: "#839abf" }}>
        Duration: 60 minutes Fee:
        <br />
        ~ 4-7 participants: $225
        <br />
        ~ 8-12 participants: $375
        <br />
        ~ 12+ participants: Contact us for a personalized quote (Pricing may
        vary for Corporate Wellness events)
        <br />
        <br />
        Ready to create an unforgettable experience? Feel free to make a request
        so we can curate an experience that aligns with your needs.
      </strong>
    </p>
  </div>
);

export default privateWellnessExperience;

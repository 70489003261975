import "./professionalBio.css";

const NicoleProfessionalJourney = (
  <div className="professional-journey">
    <p>
      Masters of Education in Clinical Mental Health Counseling:
      <br />
      <strong>Augusta University-Graduation, May 2025</strong>
      <br />
      <br />
      Bachelors of Science in Psychology: <br />
      <strong>Augusta University</strong>
      <br />
      <br />
    </p>
  </div>
);

export default NicoleProfessionalJourney;

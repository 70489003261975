import "./professionalBio.css";

const NiyaProfessionalJourney = (
  <div className="professional-journey">
    <p>
      Licensed Clinical Social Worker:
      <br />
      <strong>
        Georgia Composite Board of Professional Counselors, Social Workers, and
        Marriage &amp; Family Therapist Masters of Social Work:
      </strong>
      <br />
      <br />
      Masters of Social Work: <br />
      <strong>Valdosta State University</strong>
      <br />
      <br />
      Bachelors of Social Work: <br />
      <strong>Georgia State University</strong>
      <br />
      <br />
    </p>
  </div>
);

export default NiyaProfessionalJourney;

import "./professionalBio.css";

const NiyaBioContent = (
  <div className="info-box-style">
    <p>
      Have you ever felt stuck or feel like you have been pulled in several
      directions and then realize you don’t know which way to turn or how to
      navigate transitions? Do you notice yourself being filled with self-doubt,
      anxiety, or flooded with confusion on which decision to make? Navigating
      relationships, work, and life transitions can be overwhelming, but you
      don't have to do it alone. As a compassionate therapist, I specialize in
      helping clients manage the complexities of these pivotal moments. I am
      here to align with you while you navigate career changes, relationship
      challenges, college transitions, or just life’s unexpected turns.
      <br />
      <br />I understand, I've been there too, finding it difficult to make
      important decisions and not knowing which way to turn, but through healing
      and self- discovery, I was able to find my way. I would like to help you
      do the same. I am a Licensed Clinical Social Worker with over 10 years of
      clinical experience committed to helping those struggling with life’s
      challenges that often lead to anxiety, depression, grief or poor
      self-worth, find their own path. As a trauma-informed therapist, I firmly
      believe the individual holds the power to make change. I am here to offer
      a supportive and safe space for you to explore, overcome obstacles, while
      empowering you to thrive in every aspect of your life. I would love to
      support you as you rediscover your inner voice, happiness, and peace, feel
      free to reach out today!!
    </p>
  </div>
);

export default NiyaBioContent;
